import React, { Component } from 'react';
import { Fragment } from 'react';

import NavMenuDesktop from '../components/common/NavMenuDesktop';
import NavMenuMobile from '../components/common/NavMenuMobile';

import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
import Productdetails from '../components/productDetails/Productdetails';
import SuggestedProducts from '../components/productDetails/SuggestedProducts';

class ProductDetailsPage extends Component {
    componentDidMount(){
        window.scroll(0,0);
    }
    render() {
        return (
            <Fragment >
                <div className="Desktop">
                    <NavMenuDesktop />
                    
                </div>
                <div className="Mobile">
                    <NavMenuMobile />
                
                </div>

                <Productdetails />
                <SuggestedProducts />
            
                <div className="Desktop">
                    <FooterDesktop />
                </div>
                <div className="Mobile">
                    <FooterMobile />
                </div>
            </Fragment>
        );
    }
}

export default ProductDetailsPage;