import React, { Component, Fragment } from 'react';
import { Container, Row ,Col, Card} from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


class NewArrival extends Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.jambu.slickNext();
    }
    previous() {
        this.jambu.slickPrev();
    }

    render() {
        var settings = {
            dots: false,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            initialSlide: 0,
            autoplay:true,
            autoplaySpeed:2000,
            
           
           
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
        };
        return (
            <Container className="card mt-4" fluid={true}>
                <h1 className="pt-5 section-title">New Arrival
                    <a className="btn btn-sm ml-2 site-btn"  onClick={this.previous}>
                        <i className="fa fa-angle-left"></i>
                    </a>
                    <a className="btn btn-sm ml-2 site-btn"  onClick={this.next} >
                        <i className="fa fa-angle-right"></i>
                    </a>
                </h1> 
                <p className="section-sub-title">Some of our exclusive colection You may Like</p>
                <div>
                <Slider ref={c=>(this.jambu)=c} {...settings}>
                    <div>
                        <Card className="image-box card p-2">
                                <img src="images/featureproduct1.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className="image-box card p-2">
                                <img src="images/featureproduct1.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className="image-box card p-2">
                                <img src="images/newarrival3.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className="image-box card p-2">
                                <img src="images/newarrival3.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className="image-box card p-2">
                                <img src="images/newarrival1.jpg" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className="image-box card p-2">
                                <img src="images/newarrival1.jpg" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className="image-box card p-2">
                                <img src="images/newarrival3.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <Card className="image-box card p-2">
                                <img src="images/newarrival3.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                        </Card>
                    </div>
                </Slider>
                </div>
            </Container>
        );
    }
}

export default NewArrival;