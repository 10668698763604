import React, { Component } from 'react';
import { Col, Container, Row,Card,Button, Form} from 'react-bootstrap';

class UserOnboard extends Component {
    render() {
        return (
            <div style={{ margin: '18px' }}>
                {/* <Container >
                    <Row className="d-flex justify-content-center">
                        <Col md={4} lg={4} sm={8} xs={12}>
                            <Card border="secondary" style={{ width: '18rem' }}>
                                
                                <Card.Body >
                                    <Card.Title>Card Title</Card.Title>
                                        <Form>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>Mobile Number</Form.Label>
                                                <Form.Control type="text" placeholder="Your Phone Number" />
                                                
                                            </Form.Group>
                                        </Form>
                                    <Button variant="danger">Next</Button>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>                */}
                <Container className="TopSection">
                    <Row className="p-2">
                        <Col className="shadow-sm bg-white mt-2" md={12} lg={12} sm={12} xs={12}>
                            <Row className="text-center ">
                                <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                                    <Form className=" onboardForm">
                                        <h4 className="section-title">USER SING IN</h4>
                                        <h6 className="section-sub-title">Please Enter Your Mobile No, And Go Next</h6>
                                        <input className="form-control m-2" type="text" placeholder="Mobile Number"/>
                                        <Button className="btn btn-block m-2 site-btn">Next</Button>
                                    </Form>
                                </Col>
                                <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
                                    <img  className="onboardBanner" src="images/otppagebanner.png"/>
                                    
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default UserOnboard;