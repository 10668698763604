import React, { Component } from 'react';
import { Col, Container, Row,Card,Button, Form} from 'react-bootstrap';
class Contact extends Component {
    render() {
        return (
            <div>
                <Container className="TopSection">
                    <Row className="p-2">
                        <Col className="shadow-sm bg-white mt-2" md={12} lg={12} sm={12} xs={12}>
                            <Row className="text-center ">
                                <Col className="d-flex justify-content-center" md={6} lg={6} sm={12} xs={12}>
                                    <Form className=" onboardForm">
                                        <h4 className="section-title">Contact With Us</h4>
                                        <h6 className="section-sub-title">Stay With Us</h6>
                                        <Form.Group>
                                            <Form.Control  type="text" placeholder="Your Name"/>

                                            
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control  type="text" placeholder="Your Mobile Number"/>

                                            
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control  type="textArea" placeholder="Message"/>

                                            
                                        </Form.Group>
                                        
                                        <Form.Group>
                                            <Button className="btn btn-block  m-2 site-btn">SEND</Button>

                                            
                                        </Form.Group>
                                        
                                    </Form>
                                </Col>
                                <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.791567307946!2d91.82064871426898!3d22.32372124768711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30acd8ac5314310d%3A0xe04a827f7e674e23!2sStrand%20Road%2C%20Chittagong!5e0!3m2!1sen!2sbd!4v1633374884261!5m2!1sen!2sbd" width="400" height="300" allowfullscreen="" loading="lazy"></iframe>
                                    
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Contact;