import React, { Component, Fragment } from 'react';


import NavMenuDesktop from '../components/common/NavMenuDesktop';
import NavMenuMobile from '../components/common/NavMenuMobile';

import FooterDesktop from '../components/common/FooterDesktop';
import FooterMobile from '../components/common/FooterMobile';
import OrderForm from '../components/order/OrderForm';
class OrderPage extends Component {
    render() {
        return (
            <Fragment >
            <div className="Desktop">
                <NavMenuDesktop />
                
            </div>
            <div className="Mobile">
                <NavMenuMobile />
               
            </div>

            <OrderForm/>
           
            <div className="Desktop">
                <FooterDesktop />
            </div>
            <div className="Mobile">
                <FooterMobile />
            </div>
        </Fragment>
        );
    }
}

export default OrderPage;
