import React, { Component } from 'react';
import Slider from "react-slick";

class SliderHome extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplay:true,
            autoplaySpeed:2000,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (
            <div>
               <Slider {...settings}>
                    <div>
                        <img className="w-100" src="images/Arjaslider1.jpg"/>
                    </div>
                    <div>
                       <img className="w-100" src="images/Arjaslider2.jpg"/>
                    </div>
                    <div>
                        <img className="w-100" src="images/Arjaslider3.jpg"/>
                    </div>
                    <div>
                        <img className="w-100" src="images/Arjaslider4.jpg"/>
                    </div>
                    
                </Slider>
            </div>
        );
    }
}

export default SliderHome;