import React, { Component, Fragment } from 'react';
import { Container, Row ,Col, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

class FeaturedProducts extends Component {
    render() {
        return (
            <Fragment>
                <Container className="mt-4">
                   <h1 className="section-title">Featured Products</h1> 
                   <p className="section-sub-title">Some of our exclusive colection You may Like</p>
                    <Row >
                        <Col className="p-1" key={1} xl={3} lg={3} md={3} sm={4} xs={6}>
                           
                           <Link to="/productDetails">
                            <Card className="image-box card">
                                <img src="images/featureproduct1.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                            </Card>
                            </Link>
                        </Col>
                        <Col className="p-1" key={1} xl={3} lg={3} md={3} sm={4} xs={6}>
                            <Link to="/productDetails">
                            <Card className="image-box card">
                            <img src="images/featureproduct1.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                            </Card>
                            </Link>
                        </Col>
                        <Col className="p-1" key={1} xl={3} lg={3} md={3} sm={4} xs={6}>
                           
                            <Card className="image-box card">
                            <img src="images/featureproduct1.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="p-1" key={1} xl={3} lg={3} md={3} sm={4} xs={6}>
                           
                            <Card className="image-box card">
                            <img src="images/featureproduct1.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="p-1" key={1} xl={3} lg={3} md={3} sm={4} xs={6}>
                           
                            <Card className="image-box card">
                            <img src="images/featureproduct1.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                </Card.Body>
                            </Card>
                        </Col>
                       
                       

                        
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default FeaturedProducts;