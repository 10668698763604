import React, { Component } from 'react';
import { Fragment } from 'react';
import {Container,Row,Col,Card, Button } from 'react-bootstrap';
class Favourite extends Component {
    render() {
        return (
            <Fragment>
                <Container className="card mt-4" fluid={true}>
                    <h1 className="pt-5 section-title">Favourite Items</h1> 
                     <p className="section-sub-title">Some of our exclusive colection You may Like</p>
                    <Row>
                        <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="card image-box ">
                                <img src="images/newarrival3.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                    <div className="favoutite-remove-btn">
                                    <Button className="btn btn-sm site-btn"><i className="fa fa-trash"></i> Remove</Button>
                                    </div>
                                    
                                    
                                </Card.Body>
                                
                            </Card>
                        </Col>
                        <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="card image-box ">
                                <img src="images/newarrival3.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                    <div className="favoutite-remove-btn">
                                    <Button className="btn btn-sm site-btn"><i className="fa fa-trash"></i> Remove</Button>
                                    </div>
                                    
                                    
                                </Card.Body>
                                
                            </Card>
                        </Col>
                        <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="card image-box ">
                                <img src="images/newarrival3.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                    <div className="favoutite-remove-btn">
                                    <Button className="btn btn-sm site-btn"><i className="fa fa-trash"></i> Remove</Button>
                                    </div>
                                    
                                    
                                </Card.Body>
                                
                            </Card>
                        </Col>
                        <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="card image-box ">
                                <img src="images/newarrival3.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                    <div className="favoutite-remove-btn">
                                    <Button className="btn btn-sm site-btn"><i className="fa fa-trash"></i> Remove</Button>
                                    </div>
                                    
                                    
                                </Card.Body>
                                
                            </Card>
                        </Col>
                        <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="card image-box ">
                                <img src="images/newarrival3.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                    <div className="favoutite-remove-btn">
                                    <Button className="btn btn-sm site-btn"><i className="fa fa-trash"></i> Remove</Button>
                                    </div>
                                    
                                    
                                </Card.Body>
                                
                            </Card>
                        </Col>
                        <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="card image-box ">
                                <img src="images/newarrival3.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                    <div className="favoutite-remove-btn">
                                    <Button className="btn btn-sm site-btn"><i className="fa fa-trash"></i> Remove</Button>
                                    </div>
                                    
                                    
                                </Card.Body>
                                
                            </Card>
                        </Col>
                        <Col className="p-1" xl={3} lg={3} md={3} sm={6} xs={6}>
                            <Card className="card image-box ">
                                <img src="images/newarrival3.webp" alt="New Product"/>
                                <Card.Body>
                                    <h1 className="product-name-on-card ">Unstiched Magenta and Golden Georgatte Gown For Women</h1>
                                    <h1 className="product-price-on-card">Price: 1000Tk</h1>
                                    <div className="favoutite-remove-btn">
                                    <Button className="btn btn-sm site-btn"><i className="fa fa-trash"></i> Remove</Button>
                                    </div>
                                    
                                    
                                </Card.Body>
                                
                            </Card>
                        </Col>
                        
                        
                       
                        
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Favourite;