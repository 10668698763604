import React, { Component, Fragment } from 'react';
import { Container, Row ,Col, Card} from 'react-bootstrap';
class Categories extends Component {
    render() {
        return (
            <Fragment>
                <Container>
                   <h1 className=" pt-5 section-title">Categories</h1> 
                   <p className="section-sub-title">Some of our exclusive colection You may Like</p>
                    <Row >
                        <Col key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Row>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="h-100 w-100 text-center">
                                        <img className="w-100" src="images/newarrival2.webp" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                            
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card">
                                        <img src="images/newarrival2.webp" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card">
                                        <img src="images/newarrival2.webp" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card">
                                        <img src="images/newarrival3.webp" alt="New Product"/>
                                        <Card.Body>
                                          <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Row>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="h-100 w-100 text-center">
                                        <img className="w-100" src="images/newarrival3.webp" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>    
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card">
                                        <img src="images/newarrival3.webp" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card ">
                                        <img className="w-100" src="images/newarrival1.jpg" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card">
                                        <img className="w-100" src="images/newarrival1.jpg" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Row>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="h-100 w-100 text-center">
                                        <img className="w-100" src="images/newarrival1.jpg" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                            
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card">
                                        <img src="images/newarrival1.jpg" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card">
                                        <img src="images/newarrival1.jpg" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card">
                                        <img src="images/newarrival2.webp" alt="New Product"/>
                                        <Card.Body>
                                          <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col key={1} xl={6} lg={6} md={6} sm={12} xs={12}>
                            <Row>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="h-100 w-100 text-center">
                                        <img className="w-100" src="images/newarrival2.webp" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                            
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card">
                                        <img src="images/newarrival2.webp" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card">
                                        <img src="images/newarrival2.webp" alt="New Product"/>
                                        <Card.Body>
                                            <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col className="p-0" key={1} xl={3} lg={3} md={3} sm={6} xs={6}>
                                    <Card className="image-box card">
                                        <img src="images/newarrival1.jpg" alt="New Product"/>
                                        <Card.Body>
                                          <h1 className="category-name">Unstiched Magenta</h1>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default Categories;