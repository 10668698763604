import React, { Component} from 'react';
import { Button } from 'react-bootstrap';

class MegaMenu extends Component {

    constructor() {
        super();
      this.MegaMenu.bind(this);
    }


    componentDidMount() {
        this.MegaMenu();
    }


    MegaMenu(){
        
      var acc= document.querySelectorAll('.accordion');
    //   alert(acc);
      var accNum=acc.length;
        // alert(accNum);
     
      var i;
      for(i=0;i<accNum;i++){
          acc[i].addEventListener("click",function () {
             this.classList.toggle("active");
             var panel=this.nextElementSibling;
             
             if(panel.style.maxHeight){
                 panel.style.maxHeight=null;
             }
             else{
                 panel.style.maxHeight=panel.scrollHeight+ "px";
             }
          })
      }

    }

    render() {
        return (
            <div className="accordionMenuDiv">
                <div className="accordionMenuDivInside">
                   
                    <Button  className="accordion"> <i className="fa fa-tshirt"></i>  Men's Clothing</Button>
                    <div className="panel">
                        <ul>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                        </ul>
                        <ul>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                        </ul>
                    </div>
                    <Button  className="accordion"> <i className="fa fa-tshirt"></i>    Men's Clothing</Button>
                    <div className="panel">
                        <ul>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                        </ul>
                        <ul>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                        </ul>
                    </div>

                    <Button  className="accordion"> <i className="fa fa-tshirt"></i>  Men's Clothing</Button>
                    <div className="panel">
                        <ul>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                        </ul>
                        <ul>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                        </ul>
                    </div>

                    <Button  className="accordion"> <i className="fa fa-tshirt"></i>  Men's Clothing</Button>
                    <div className="panel">
                        <ul>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                        </ul>
                        <ul>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                            <li><a href="#" className="accordionItem">Man Shirt</a></li>
                        </ul>
                    </div>
                    

                </div>
            </div>
        );
    }
}

export default MegaMenu;